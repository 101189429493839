import React, { useState, useContext }  from "react"
import { graphql, Link } from "gatsby"
import get from 'lodash/get';
import SEO from "../components/seo"
import Hero from "../components/heros/hero"
import HTMLEllipsis from "react-lines-ellipsis/lib/html"
import { FirebaseContext } from "../components/Firebase"
import { navigate } from "gatsby"
import "../components/component.css"
import "./story.css"

// @TODO replace view more functionality and remove eslint disables

export default function StoryTemplate({ data, pageContext }) {
  const post = get(data, 'singleStory')
  const relatedStories = get(data, 'relatedStories')
  const educationgroup = post.metadata.story_education_group === null ? [] : post.metadata.story_education_group
  const storygroup = relatedStories.nodes
  const [isExpandHow, setExpandHow] = useState(false)
  const [isExpandProf, setExpandProf] = useState(false)
  const [isExpandWork, setExpandWork] = useState(false)
  const [isExpandDay, setExpandDay] = useState(false)
  const [isExpandHardest, setExpandHardest] = useState(false)
  const [isExpandBest, setExpandBest] = useState(false)

  const {user, loading} = useContext(FirebaseContext)
  // hold up rendering until we're loaded.
  if (!user && !pageContext.allowedPageIds.includes(post.id)) {
    if (loading) {
      return (
        <>
        </>
      )
    }
    else {
      navigate("/403")
      return null
    }
  }

  return (
    <section>
      <SEO title="Story" />
      <Hero url={post.metadata.story_banner_image.imgix_url} />

      {(post.metadata.story_job_category_reference) &&
        <section className="categories">
          <div className="inner inner-categories">
            <span className="categories-list-header">Categories:</span>
            <ul className="categories-list">
              <li className="categories-list-item">{post.metadata.story_job_category_reference.title}</li>
                {post.metadata.story_job_subcategory_reference && post.metadata.story_job_subcategory_reference.map((categories, idx) => {
                  if (idx < 3) {
                    return (
                      <li className="categories-list-item" key={idx}>{categories.title}</li>
                    )
                  }
                  return null;
                })}
            </ul>
          </div>
        </section>
      }

      <section className="story-header" style={{ backgroundColor: 'var(--color-f6f6f6)' }}>
        <div className="inner inner-story inner-story-header">
          <div className="story-photo">
            <img src={post.metadata.story_personal_photo.imgix_url} alt="" />
          </div>
          <div className="story-attributes">

            <h1>{post.title}</h1>
            <div className="story-details story-details-job-title">
              <div className="eyebrow component-eyebrow">Job Title</div>
              {post.metadata.story_job_title}
            </div>
            <div className="story-details story-details-company">
              <div className="eyebrow component-eyebrow">Company</div>
                {post.metadata.story_company}
            </div>
            <div className="story-details story-details-website">
              <div className="eyebrow component-eyebrow">Website</div>
              {post.metadata.story_website}
            </div>
            <div className="story-details story-details-location">
              <div className="eyebrow component-eyebrow">Location</div>
              {post.metadata.story_location}
            </div>
            <div className="story-details story-details-salary">
              <div className="eyebrow component-eyebrow">Salary</div>
              {post.metadata.story_salary}
            </div>
            <div className="story-details story-details-education">
              <div className="eyebrow component-eyebrow">Education</div>
              {educationgroup.map((edu, idx) => (
                <div className="grouping" key={idx}>
                  {edu.school} – {edu.degree}, {edu.study}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="story-advice-quote">
        <div className="inner inner-story inner-story-advice-quote">
          <blockquote className="component-blockquote component-blockquote-story">
            <div className="eyebrow component-eyebrow component-eyebrow-story">Advice for someone thinking about going into the field</div>
            {post.metadata.story_quote}
          </blockquote>
        </div>
      </section>

      <section className="story-answers">
        <div className="inner inner-story inner-story-answers">
          <div className="story-answers story-answers-video">
              <div dangerouslySetInnerHTML={{__html: post.metadata.story_video}} />
          </div>
          <div className="story-answers story-answers-profession">
            <div className="eyebrow component-eyebrow">The Profession</div>
            {isExpandProf ? (
              <div dangerouslySetInnerHTML={{__html: post.metadata.story_profession}}/> // eslint-disable-line
              ) : (
              <div style={{ position: 'relative' }} onClick={() => setExpandProf(!isExpandProf)} onKeyDown={() => setExpandProf(!isExpandProf)}> {/* eslint-disable-line */}
                <HTMLEllipsis
                  unsafeHTML={post.metadata.story_profession}
                  maxLine='10'
                  ellipsis='...'
                  basedOn='letters'
                />
                <div className="view-more"><hr></hr>View More</div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="story-extra">
        <div className="inner inner-story inner-story-extra">
          <div className="story-view-wrapper flex">
            {post.metadata.story_hardest.length > 0 &&
              <div className="story-answers story-answers-hardest">
                <div className="eyebrow component-eyebrow">The Hardest Parts</div>
                {isExpandHardest ? (
                  <div dangerouslySetInnerHTML={{__html: post.metadata.story_hardest}} />
                  ) : (
                  <div style={{ position: 'relative' }} onClick={() => setExpandHardest(!isExpandHardest)}> {/* eslint-disable-line */}
                    <HTMLEllipsis
                      unsafeHTML={post.metadata.story_hardest}
                      maxLine='3'
                      ellipsis='...'
                      basedOn='letters'
                    />
                    <div className="view-more grey"><hr></hr>View More</div>
                  </div>
                )}
              </div>
            }
            {post.metadata.story_best.length > 0 &&
              <div className="story-answers story-answers-best">
                <div className="eyebrow component-eyebrow">The Best Parts</div>
                {isExpandBest ? (
                  <div dangerouslySetInnerHTML={{__html: post.metadata.story_best}} />
                  ) : (
                  <div style={{ position: 'relative' }} onClick={() => setExpandBest(!isExpandBest)}> {/* eslint-disable-line */}
                    <HTMLEllipsis
                      unsafeHTML={post.metadata.story_best}
                      maxLine='3'
                      ellipsis='...'
                      basedOn='letters'
                    />
                    <div className="view-more grey"><hr></hr>View More</div>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      </section>

      <section className="story-answers">
        <div className="inner inner-story inner-story-answers">
          {post.metadata.story_how.length > 0 &&
            <div className="story-answers story-answers-how">
              <div className="eyebrow component-eyebrow">How I Got Here</div>
              {isExpandHow ? (
                <div dangerouslySetInnerHTML={{__html: post.metadata.story_how}}/>
                ) : (
                <div style={{ position: 'relative' }} onClick={() => setExpandHow(!isExpandHow)}> {/* eslint-disable-line */}
                  <HTMLEllipsis
                    unsafeHTML={post.metadata.story_how}
                    maxLine='10'
                    ellipsis='...'
                    basedOn='letters'
                  />
                  <div className="view-more"><hr></hr>View More</div>
                </div>
              )}
            </div>
          }
          {post.metadata.story_day.length > 0 &&
            <div className="story-answers story-answers-day">
              <div className="eyebrow component-eyebrow">A Typical Day</div>
              {isExpandDay ? (
                <div dangerouslySetInnerHTML={{__html: post.metadata.story_day}}/>
                ) : (
                <div style={{ position: 'relative' }} onClick={() => setExpandDay(!isExpandDay)}> {/* eslint-disable-line */}
                  <HTMLEllipsis
                    unsafeHTML={post.metadata.story_day}
                    maxLine='10'
                    ellipsis='...'
                    basedOn='letters'
                  />
                  <div className="view-more"><hr></hr>View More</div>
                </div>
              )}
            </div>
          }
          {post.metadata.story_workplace.length > 0 &&
            <div className="story-answers story-answers-workplace">
              <div className="eyebrow component-eyebrow">The Workplace</div>
              {isExpandWork ? (
                <div dangerouslySetInnerHTML={{__html: post.metadata.story_workplace}}/>
                ) : (
                <div style={{ position: 'relative' }} onClick={() => setExpandWork(!isExpandWork)}> {/* eslint-disable-line */}
                  <HTMLEllipsis
                    unsafeHTML={post.metadata.story_workplace}
                    maxLine='10'
                    ellipsis='...'
                    basedOn='letters'
                  />
                  <div className="view-more"><hr></hr>View More</div>
                </div>
              )}
            </div>
          }
        </div>
      </section>

      {post.metadata.story_myths.length > 0 &&
        <section className="story-extra">
          <div className="inner inner-story inner-story-extra">
            <div className="story-answers story-answers-myths">
              <div className="eyebrow component-eyebrow">Myths of the Profession</div>
              <div dangerouslySetInnerHTML={{__html: post.metadata.story_myths}} />
            </div>
          </div>
        </section>
      }

      <section className="story-answers">
        <div className="inner inner-story inner-story-answers">
          {post.metadata.story_advice.length > 0 &&
            <div className="story-answers story-answers-advice">
                <div className="eyebrow component-eyebrow">Advice for someone thinking about going into the field</div>
                <div dangerouslySetInnerHTML={{__html: post.metadata.story_advice}} />
            </div>
          }
          {post.metadata.story_advice_younger.length > 0 &&
            <div className="story-answers story-answers-advice-younger">
              <div className="eyebrow component-eyebrow">Advice to my Younger Self</div>
              <div dangerouslySetInnerHTML={{__html: post.metadata.story_advice_younger}} />
            </div>
          }
        </div>
      </section>

      {(post.metadata.story_job_category_reference) &&
        <section className="categories">
          <div className="inner inner-categories">
            <span className="categories-list-header">Categories:</span>
            <ul className="categories-list">
              <li className="categories-list-item">{post.metadata.story_job_category_reference.title}</li>
                {post.metadata.story_job_subcategory_reference && post.metadata.story_job_subcategory_reference.map((categories, idx) => {
                  if (idx < 3) {
                    return (
                      <li className="categories-list-item" key={idx}>{categories.title}</li>
                    )
                  }
                  return null;
                })}
            </ul>
          </div>
        </section>
      }

      <section className="story-breadcrumb">
        <div className="inner inner-story inner-story-breadcrumb">
          <div className="similar-careers">
            {relatedStories.nodes.length > 0 &&
              <>
              <span className="label">Similar careers:</span>
              <ul>
                {storygroup.map((story, idx) => (
                  <li key={idx}><Link to={`/story/${story.slug}`}>{story.title}</Link></li>
                ))}
              </ul>
              </>
            }
          </div>
          <div className="as-told">
            <span>As told to </span>{post.metadata.story_interviewed_by}<br/>
            <strong>DATE:</strong> {post.metadata.story_interview_date}
          </div>
        </div>
      </section>

      <section className="signup" style={{ backgroundColor: '#54d1c1' }}>
        <div className="inner inner-signup">
          <div className="call-to-action component-call-to-action component-call-to-action-signup">
            <blockquote className="component-blockquote component-blockquote-default-inline">
              <p>“Each other’s lives are our best textbooks.”</p>
              <cite>– Gloria Steinem</cite>
            </blockquote>
            <button type="button" className="button component-button component-button-signup">Sign Up</button>
          </div>
        </div>
      </section>
    </section>
  )
}

export const pageQuery = graphql`
query StoryQuery($slug: String!, $relatedStories: String!) {
    singleStory: cosmicjsStories(slug: { eq: $slug }) {
      id
      title
      metadata {
        story_advice
        story_advice_younger
        story_best
        story_company
        story_day
        story_hardest
        story_how
        story_interview_date
        story_interviewed_by
        story_job_title
        story_location
        story_myths
        story_profession
        story_quote
        story_salary
        story_video
        story_website
        story_workplace
        story_personal_photo {
          imgix_url
        }
        story_education_group {
          degree
          school
          study
        }
        story_banner_image {
          imgix_url
        }
        story_job_category_reference {
          title
          _id
        }
        story_job_subcategory_reference {
          title
        }
        story_career_reference {
          title
        }
      }
    }
    relatedStories: allCosmicjsStories(
      limit: 3,
      filter: {
        metadata: {
          story_job_category_reference: {
            _id: {
              eq: $relatedStories
            }
          }
        }
        slug: {
          ne: $slug
        }
      }
    ) {
      nodes {
        title
        slug
        id
      }
    }
  }
`
